import { useGrowthBook } from '@growthbook/growthbook-react'
import { useCallback, useContext, useState } from 'react'

import { addDataToProfile, getDataFromKeeper } from '@api/profile'

import { GROWTH_BOOK_DEFAULT_VALUE } from '@constants'

import { AuthContext } from '@modules/Auth/AuthProvider'

export const EXPERIMENTS_INVOLVED = 'ff_experiments_involved'

const useUpdateGrowthBookKeeper = (): ((splitData: {
  key?: string
  payload: { key: string; value: string }
}) => void) => {
  const { globalUser } = useContext(AuthContext)
  const growthBook = useGrowthBook()
  const [isSent, setIsSent] = useState(false)

  return useCallback(
    async (splitData: {
      key?: string
      payload: { key: string; value: string }
    }) => {
      const token = await globalUser?.getIdToken()
      if (
        !token ||
        splitData.payload.value === GROWTH_BOOK_DEFAULT_VALUE ||
        isSent
      ) {
        return
      }
      try {
        const keeperData = await getDataFromKeeper(token, EXPERIMENTS_INVOLVED)
        // we expect here a string with comma separated values. like 'value1,value2,value3'
        // we agreed this format across platforms
        const previousArrayOfExperiments = keeperData?.value?.split(',') || []
        const alreadyInExperiment = previousArrayOfExperiments.includes(
          splitData.payload.key
        )
        const nextArrayOfExperiments = alreadyInExperiment
          ? previousArrayOfExperiments
          : previousArrayOfExperiments.concat(splitData.payload.key)

        growthBook.updateAttributes({
          ...growthBook.getAttributes(),
          [EXPERIMENTS_INVOLVED]: nextArrayOfExperiments,
        })

        const payload = nextArrayOfExperiments.join(',')

        if (!alreadyInExperiment) {
          const data = {
            key: EXPERIMENTS_INVOLVED,
            payload,
          }
          addDataToProfile(token, data).then(() => {
            setIsSent(true)
          })
        } else {
          setIsSent(true)
        }
      } catch {
        // if got 404
        growthBook.updateAttributes({
          ...growthBook.getAttributes(),
          [EXPERIMENTS_INVOLVED]: [splitData.payload.key],
        })

        const data = {
          key: EXPERIMENTS_INVOLVED,
          payload: String(splitData.payload.key),
        }
        addDataToProfile(token, data).then(() => {
          setIsSent(true)
        })
      }
    },
    [globalUser, growthBook, isSent]
  )
}

export default useUpdateGrowthBookKeeper
