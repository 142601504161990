export const LOCALES = [
  'en',
  'uk',
  'es',
  'pt',
  'fr',
  'tr',
  'it',
  'de',
  'pl',
] as const
export const DEFAULT_LOCALE = 'en'

export const SWITCH_DELAY = 850 // ms
export const USER_LEVEL_QUESTION_ID = 'user_language_level'
export const USER_GOAL_LEVEL_QUESTION_ID = 'goal_language_level'
export const MOTIVATION = 'motivation'
export const USER_AGE = 'user_age'
export const ASPECTS_TO_IMPROVE = 'aspects_to_improve'
export const I_DO_NOT_KNOW = 'i-do-not-know'
export const ONE_ON_ONE = 'one-on-one'

export const USER_PURCHASE_LS_KEY = 'userPurchase'

export const USER_EMAIL_LS_KEY = 'userEmail'
export const FAQ_PRICING_PAGE = 'FAQ_pricing_page'

export const USER_ANSWERS = 'user_answers'
export const USER_SHORT_ONBOARDING_ANSWERS = 'user_short_onboarding_answers'
export const USER_COURSES_ONBOARDING_ANSWERS = 'user_courses_onboarding_answers'
export const USER_LESSONS_ONBOARDING_ANSWERS = 'user_lessons_onboarding_answers'

export const USER_NAME_ID = 'user_name'

export const WORDS_PER_WEEK = {
  Freshman: 12,
  Student: 18,
  Guru: 24,
}

export const DEFAULT_DOLLAR_SYMBOL = '$'

export const AM_PM_COUNTRIES = ['US', 'CA', 'AU', 'NZ', 'PH']

export const SUPPORT_EMAIL = 'support@promova.com'
export const TUTORS_SUPPORT_EMAIL = 'tutors@promova.com'
export const PARTNERSHIPS_EMAIL = 'partnerships@promova.com'
export const ENTRY_POINT = 'entry_point'
export const COMPLETED_FIRST_LESSON = 'completed_first_lesson'

export const MIN_UNPAID_TIME = 24 // hours
export const FLOW_ID = 'flow_id'

export const directLinks = {
  apple: 'https://apps.apple.com/US/app/id1460782849',
  google: 'https://play.google.com/store/apps/details?id=com.appsci.tenwords',
  huawei: 'https://appgallery.huawei.com/app/C104818781',
  instagram: 'https://www.instagram.com/promova/',
  facebook: 'https://www.facebook.com/promova',
  tiktok: 'https://www.tiktok.com/@promova.app',
  crunchbase: 'https://www.crunchbase.com/organization/promova',
  wikidata: 'https://www.wikidata.org/wiki/Q122227827',
}

export const IOS_CANCEL_LINK = 'https://support.apple.com/HT202039'
export const ANDROID_CANCEL_LINK =
  'https://support.google.com/googleplay/answer/7018481'

export const ALPHABET = [
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'v',
  'w',
  'x',
  'y',
  'z',
]

export const observeUtms: string[] = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
  'utm_ad',
  'utm_funnel',
  'fbclid',
  'utm_cta',
  'utm_campaign_id',
  'utm_adset_id',
  'utm_placement',
  'utm_adset_name',
  'utm_campaign_name',
  'utm_adgroupid',
  'utm_keyword',
  'utm_ad_name',
  'utm_ad_id',
]

export const UTMS_FROM_SOURCEBUSTER: Record<string, string> = {
  src: 'utm_source',
  mdm: 'utm_medium',
  cmp: 'utm_campaign',
  trm: 'utm_term',
  cnt: 'utm_content',
  typ: 'traffic_type',
}

export const LIVE_MODE = process.env.NEXT_PUBLIC_LIVE_MODE === 'true'
export const projectName = LIVE_MODE
  ? 'Words Platform - Promova'
  : 'Words Platform - TEST'

export const EMAIL_CONTACT = 'jane.riley@promova.com'
export const WHATSAPP_PHONE_NUMBER = '+447488811631'

export const TESTS_BANNER_CLOSED = 'tests_banner_closed'

export const TESTS_SATISFACTION_MODAL_CLOSED = 'tests_satisfaction_modal_closed'
export const FILLING_OUT_POINT = 'filling_out_point'

export const URL_BEFORE_FUNNEL_ID = 'url_before_funnel'

export const dateFormat = {
  en: 'MMM d, y',
  uk: 'd MMM y',
  es: 'd MMMM y',
  de: 'd, MMM y',
  it: 'd, MMM y',
  tr: 'd, MMM y',
  pt: 'd, MMM y',
  ar: 'd, MMM y',
} as const

export const DATE_FORMAT = new Proxy(dateFormat, {
  get(target: typeof dateFormat, locale: keyof typeof dateFormat): string {
    return locale in target ? target[locale] : target[DEFAULT_LOCALE]
  },
})

export const BACKEND_ERROR = 'backend_error'

export const SITE_URL =
  process.env.SITE_URL || process.env.VERCEL_URL || 'https://promova.com'

export const RSS_FEEDS = [
  {
    title: 'General article by Promova',
    href: 'https://promova.com/blog/feed',
  },
  {
    title: 'Grammar article by Promova',
    href: 'https://promova.com/skills/english-grammar/feed',
  },
  {
    title: 'Vocabulary article by Promova',
    href: 'https://promova.com/skills/english-vocabulary/feed',
  },
]

export const REVIEW_PLATFORM_URL = {
  TRUSTPILOT: 'https://www.trustpilot.com/evaluate/promova.com',
  GOOGLE_BUSINESS: 'https://g.page/r/Cd4EmIl5_ia7EBE/review',
}

export const SPEECH_WORD_SECTION = 'WordSection'

export const CONFUSING_WORD_SECTION = 'WordSection'

// used in '/conversation-club' to remove the top indent from the page in mobile view
export const TOP_INDENT_MOBILE = 80
export const CTA_BANNER_MOBILE_ONLY_HEIGHT = 71

export const USER_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone

export const STRAPI_LOCALIZATIONS_PARAMS = {
  localizations: {
    populate: {
      seo: {
        populate: '*',
      },
    },
  },
}

export const IS_PRODUCTION = process.env.NODE_ENV === 'production'

export const HOME_PATH = '/'

export const SKIP_BUILD_STATIC_GENERATION =
  process.env.SKIP_BUILD_STATIC_GENERATION === 'true'

export const REVALIDATE_CACHE_INTERVAL = 30
export const FIVE_MINUTES = 300
export const TEN_MINUTES = 600
export const TWO_HOURS = 7200
export const THREE_DAYS_IN_MILLISECONDS = 259200000
export const THIRTY_DAYS_IN_MILLISECONDS = 2592000000

export enum SupportedSocialLoginMethods {
  apple = 'apple.com',
  google = 'google.com',
}

export const SUPPORTED_SOCIAL_LOGIN_METHODS = Object.values(
  SupportedSocialLoginMethods
)

export const UNBREAKABLE_SPACE = '\u00A0'

export enum LinkTarget {
  BLANK = '_blank',
  SELF = '_self',
}

export enum Domains {
  PRODUCTION = 'promova.com',
  DEVELOPMENT = 'promova.work',
  PREVIEW = 'vercel.app',
}

export const SNACKBAR_VISIBILITY_TIME = 5000 // 5 seconds

export const EMPTY_LINK = '#'
