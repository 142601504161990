import { useGrowthBook } from '@growthbook/growthbook-react'
import { formatISO } from 'date-fns'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

import { getDeviceIdFromCookies } from '@promova/utils/getDeviceIdFromCookies'

import { getDataFromKeeper } from '@api/profile'

import { useAppSelector } from '@reduxStore/hooks'

import { COMPLETED_FIRST_LESSON } from '@constants'

import { SubscriptionStatus } from '@_types/subscription'

import { useQueryCountry } from '@utils/useQueryCountry'
import { EXPERIMENTS_INVOLVED } from '@utils/useUpdateGrowthBookKeeper'

import { useAuthContext } from '@modules/Auth/AuthProvider'

const useAddGrowthBookAttributes = () => {
  const growthBook = useGrowthBook()
  const { country } = useQueryCountry()
  const { globalUser, user } = useAuthContext()
  const { query, isReady } = useRouter()

  const userId = globalUser?.uid

  const hasCoursesPremium = useAppSelector(
    (state) => state.profile.hasCoursesPremium
  )
  const isSubscriptionFetched = useAppSelector(
    (state) => state.profile.isSubscriptionFetched
  )

  const userProfile = useAppSelector((state) => state.profile.userProfile)
  const isProfileFetched = userProfile?.user_id
  const isFirstLessonCompleted =
    userProfile?.keeper?.[COMPLETED_FIRST_LESSON]?.value || false

  useEffect(() => {
    if (!userId || !growthBook || !country || !isReady) return

    const isMobile = navigator.maxTouchPoints > 0 && 'orientation' in window
    const utmSource = query?.utm_source || 'organic'
    const deviceId = getDeviceIdFromCookies()

    const attributes: Record<string, any> = {
      custom_user_id: userId,
      country,
      utm_source: utmSource,
      device: isMobile ? 'mobile' : 'desktop',
      device_id: deviceId,
      platform: 'web',
    }

    const setAttributesForAuthenticated = async () => {
      if (user && isSubscriptionFetched && isProfileFetched) {
        attributes.email = user.email
        attributes.self_learning_premium_access = hasCoursesPremium
          ? SubscriptionStatus.PREMIUM
          : SubscriptionStatus.FREE
        attributes.user_created_date = formatISO(
          new Date(user.metadata.creationTime || '')
        )
        attributes.completed_first_lesson = isFirstLessonCompleted

        try {
          const token = await user.getIdToken()
          const keeperData = await getDataFromKeeper(
            token,
            EXPERIMENTS_INVOLVED
          )
          const experimentsInvolved = keeperData?.value?.split(',') || []

          attributes.ff_experiments_involved = experimentsInvolved
        } catch {
          // experiments involved not found or failed to fetch
        }
      }

      // this call inside async function because we need await promises to be resolved
      growthBook.updateAttributes(attributes)
      ;(window as any).userAttributes = growthBook.getAttributes()
    }
    setAttributesForAuthenticated()
  }, [
    userId,
    growthBook,
    country,
    query,
    isReady,
    user,
    isSubscriptionFetched,
    isProfileFetched,
    hasCoursesPremium,
    isFirstLessonCompleted,
  ])
}

export default useAddGrowthBookAttributes
